import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import MembersDataService from '@/services/MembersDataService'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub from '@/types/SysClub'

export default class clubPointRules extends Vue {
  $Message: any
  clubData: SysClub = {} as SysClub
  clubRules: any = []
  pointRules: any = [{ id: 0, point: 0, start_range: 0, end_range: 0, type: '', klubber_id: 0 }]
  typeOptions: any = [{ value: 'lukket', name: 'Lukket' }, { value: 'dart', name: 'Dart' }, { value: 'winner', name: 'Winner' }, { value: 'participation', name: 'Participation' }]
  editRulesId: any = []

  public retrieveClubInformation () {
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    let tempClub = {} as SysClub
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        const tempMember = response.data

        if (tempMember.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })
          return
        }

        // usrgroup 3 er klubansvarlig
        if (tempMember[0].user_id.usrgroup !== 3) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })

          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }

        ClubsDataService.get(Number(tempMember[0].klubber_id.id).toString())
          .then((response) => {
            tempClub = response.data
            this.clubData = tempClub
            this.retriveClubTournamentPointRules(this.clubData.id)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async retriveClubTournamentPointRules (clubId: any) : Promise<void> {
    try {
      const rules = await ClubsDataService.getClubTournamentExtraPointRules(clubId.toString())
      this.clubRules = rules.data
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('clubRules')
  onClubDataChange () : void {
    this.clubRules.length && this.addClubRuleDataInPointRules()
  }

  public addClubRuleDataInPointRules () : void {
    this.pointRules = []
    const order = ['lukket', 'dart']
    this.clubRules.sort((a: any, b: any) => {
      if (!a.type) return 1
      if (!b.type) return -1
      return order.indexOf(a.type) - order.indexOf(b.type)
    })
    this.clubRules.map((rule: any) => {
      const ruleData = {
        id: rule.id,
        point: rule.point,
        start_range: rule.start_range,
        end_range: rule.end_range,
        type: rule.type,
        klubber_id: this.clubData.id
      }
      this.pointRules.push(ruleData)
    })
  }

  public addNewField () : void {
    const field = { id: 0, point: 0, start_range: 0, end_range: 0, type: '', klubber_id: this.clubData.id ? this.clubData.id : 0 }
    this.pointRules.push(field)
  }

  public async removeField (index: number) : Promise<void> {
    const selectedRuleId = this.pointRules[index].id
    if (selectedRuleId) {
      await ClubsDataService.deleteClubTournamentExtraPointRule(selectedRuleId)
        .then(() => {
          this.$Message.success({ text: 'Rule Deleted' })
          this.pointRules.splice(index, 1)
        })
        .catch((err) => {
          console.log(err)
          this.$Message.danger({ text: 'Not Delete' })
        })
    } else {
      this.pointRules.splice(index, 1)
    }
  }

  public async editField (data: any): Promise<void> {
    const editRulePayload = {
      point: data.point,
      start_range: data.start_range,
      end_range: data.end_range,
      type: data.type
    }
    await ClubsDataService.updateClubTournamentExtraPointRule(data.id, editRulePayload)
      .then(() => {
        this.$Message.success({ text: 'Rule Updated' })
        this.editRulesId.includes(data.id) && this.editRulesId.splice(this.editRulesId.indexOf(data.id), 1)
      })
      .catch((err) => {
        console.error(err)
        this.$Message.danger({ text: 'Not Updated' })
      })
  }

  public async createPointRules () : Promise<void> {
    const promises: Promise<any>[] = []
    const createRulePromise = async (params: any) => {
      return ClubsDataService.createClubTournamentExtraPointRule(params)
    }
    this.pointRules.map(async (pointRule: any) => {
      if (!pointRule.id && pointRule.type) {
        const rulePayload = {
          point: pointRule.point,
          start_range: pointRule.start_range,
          end_range: pointRule.end_range,
          type: pointRule.type,
          klubber_id: this.clubData.id
        }
        promises.push(createRulePromise(rulePayload))
      }
    })
    if (promises.length) {
      await Promise.all(promises)
        .then((res) => {
          this.$Message.success({ text: 'Point reglerne er oprettet' })
        })
        .catch((err) => {
          this.$Message.danger({ text: 'Rules not created' })
          console.error(err)
        })
        .finally(() => {
          this.retriveClubTournamentPointRules(this.clubData.id)
        })
    } else {
      this.$Message.warning({ text: 'Nothing to change' })
    }
  }

  public onFieldEdit (id: any) {
    !this.editRulesId.includes(id) && this.editRulesId.push(id)
  }

  public backToPortal () : void {
    window.history.back()
  }

  public checkForWinnerAndParticipation (type : string) : boolean {
    return !(type === 'lukket' || type === 'dart')
  }

  public isDisabledToEdit (id : number) : boolean {
    if (!id) {
      return true
    } else {
      return !this.editRulesId.includes(id)
    }
  }

  async mounted () : Promise<void> {
    this.retrieveClubInformation()
  }
}
